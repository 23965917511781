<div class="modal-header">
  <div>
    <h4 class="modal-title" id="modal-basic-title">Edit timesheet entry</h4>
    <span class="modal-title-running-time" *ngIf="isTimerRunning && currentTimer?.timesheetId === timesheetDetail.id">{{
      (editTimeSheetValuesChanged.isCategoryChanged ||
        editTimeSheetValuesChanged.isClientChanged ||
        editTimeSheetValuesChanged.isDescriptionChanged ||
        editTimeSheetValuesChanged.isMatterChanged ||
        editTimeSheetValuesChanged.isTimeEntryTypeChanged) &&
      !!timesheetDetail.lastLoggedTime
        ? lastLoggedTimeString
        : currentTimeString
    }}</span>
  </div>
  <button type="button" class="close" (click)="closeModal.emit()" aria-label="Close">
    <svg-icon name="modal-close-icon" class="icon"></svg-icon>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="editTimesheetEntry" class="edit-timesheet-form">
    <div class="row">
      <div class="col-sm-12">
        <div class="form-field form-field-select">
          <label for="editTimesheetTimeEntryType">Time Entry Type</label>
          <ng-select
            labelForId="editTimesheetTimeEntryType"
            [items]="timeEntryTypes"
            [multiple]="false"
            [closeOnSelect]="true"
            [searchable]="true"
            bindLabel="timeEntryTypeName"
            bindValue="id"
            placeholder="Time Entry"
            [clearable]="false"
            [ngModel]="selectedTimeEntryTypeEditTimesheetEntry"
            [ngModelOptions]="{ standalone: true }"
            (change)="timeEntryTypeSelected($event, true); checkIfTimeSheetEditValuesChanged()"
          >
          </ng-select>
        </div>
      </div>
      <div class="col-sm-12">
        <div class="form-field form-field-select">
          <label for="editTimesheetClient">Client</label>
          <ng-select
            labelForId="editTimesheetClient"
            [items]="clientListOfEditTimesheetEntry"
            [multiple]="false"
            [closeOnSelect]="true"
            [searchable]="true"
            bindLabel="clientName"
            bindValue="id"
            [(ngModel)]="selectedClientEditTimesheetEntry"
            [ngModelOptions]="{ standalone: true }"
            placeholder="Select Client"
            [virtualScroll]="true"
            (change)="
              onClientSelectionChanged($event, true);
              getMattersByClientIdEditTimesheet();
              checkIfTimeSheetEditValuesChanged()
            "
            [disabled]="selectedTimeEntryTypeEditTimesheetEntry === 2"
          >
          </ng-select>
        </div>
        <div
          class="no-matter-assigned-error automatic-time-entry"
          *ngIf="!isPreviouslySelectedClientExistTimesheetEdit"
        >
          <mat-error
            >Matters for <u>{{ previouslySelectedClientNameTimesheetEdit }}</u> is not assigned to you.
          </mat-error>
        </div>
      </div>
      <div class="col-sm-12">
        <div class="form-field form-field-select">
          <label for="editTimesheetMatter">Matter</label>
          <ng-select
            appendTo="body"
            labelForId="editTimesheetMatter"
            [items]="matterListOfEditTimesheetEntry"
            [multiple]="false"
            [closeOnSelect]="true"
            [searchable]="true"
            bindLabel="matterName"
            bindValue="id"
            [(ngModel)]="selectedMatterEditTimesheetEntry"
            [ngModelOptions]="{ standalone: true }"
            placeholder="Select Matter"
            [virtualScroll]="true"
            (change)="getClientByMatterIDForTimesheetEdit($event); checkIfTimeSheetEditValuesChanged();checkIfRoundingApplies(this.selectedTimeEntryTypeEditTimesheetEntry, this.selectedClientEditTimesheetEntry ?? 0, $event?.id);"
            [disabled]="selectedTimeEntryTypeEditTimesheetEntry === 3"
          >
          </ng-select>
          <mat-error *ngIf="selectedMatterEditTimesheetEntry && !isSelectedMatterEditTimesheetEntryActive"
            >Matter is inactive
          </mat-error>
        </div>
      </div>
      <div class="col-sm-12">
        <div class="form-field form-field-select">
          <label for="editTimesheetHeadlinePracticeArea">Headline Practice Area</label>
          <ng-select
            labelForId="editTimesheetHeadlinePracticeArea"
            [items]="headLinePracticeAreaByMatterId"
            [multiple]="false"
            [closeOnSelect]="true"
            [searchable]="true"
            bindLabel="headlinePracticeAreaName"
            bindValue="id"
            [(ngModel)]="selectedHeadlinePracticeAreaEditTimesheetEntry"
            [ngModelOptions]="{ standalone: true }"
            placeholder="Headline Practice Area"
            [virtualScroll]="true"
            [disabled]="true"
          >
          </ng-select>
        </div>
      </div>
      <div class="col-sm-12">
        <div class="form-field form-field-select">
          <label for="editTimesheetPhase">Phase</label>
          <ng-select
            labelForId="editTimesheetPhase"
            [items]="phaseByMatterId"
            [multiple]="false"
            [closeOnSelect]="true"
            [searchable]="true"
            [(ngModel)]="selectedPhaseEditTimesheetEntry"
            [disabled]="selectedTimeEntryTypeEditTimesheetEntry === 3"
            [ngModelOptions]="{ standalone: true }"
            placeholder="Select Phase"
            [virtualScroll]="true"
          >
          </ng-select>
        </div>
      </div>
      <div class="col-sm-12">
        <div class="form-field form-field-select">
          <label for="editTimesheetCategory">Category</label>
          <ng-select
            labelForId="editTimesheetCategory"
            [items]="categoryListOfManualEntry"
            [multiple]="false"
            [closeOnSelect]="true"
            [searchable]="true"
            bindLabel="categoryName"
            bindValue="id"
            [(ngModel)]="selectedCategoryOfEditTimesheetEntry"
            [ngModelOptions]="{ standalone: true }"
            (change)="checkIfTimeSheetEditValuesChanged()"
            placeholder="Select Category"
          >
          </ng-select>
        </div>
      </div>
      <div class="col-sm-12">
        <div
          class="form-field form-field-select desc-input form-filed-icon"
          [ngClass]="{ 'description-pattern-error': editTimesheetEntry.controls.description.hasError('pattern') }"
        >
          <label for="editTimesheetDescription">Description</label>
          <textarea
            id="editTimesheetDescription"
            matInput
            formControlName="description"
            placeholder="Enter Description"
            name="description"
            rows="1"
            (keyup)="descriptionInputSubject.next(editTimesheetEntry.get('description')?.value)"
          ></textarea>
          <svg-icon name="matter-icon" class="input-icon"></svg-icon>
          <mat-error
            *ngIf="
              editTimesheetEntry.controls.description.touched &&
              editTimesheetEntry.controls.description.hasError('descriptionRequired') &&
              !editTimesheetEntry.controls.description.hasError('invalidDescription')
            "
            >{{ validationMessages.descriptionRequired }}
          </mat-error>
          <mat-error
            *ngIf="
              editTimesheetEntry.controls.description.touched &&
              editTimesheetEntry.controls.description.hasError('limitExceeds') &&
              !editTimesheetEntry.controls.description.hasError('invalidDescription')
            "
            >{{ validationMessages.maxLength2000ForDescription }}
          </mat-error>
          <mat-error
            *ngIf="
              editTimesheetEntry.controls.description.touched &&
              !(
                editTimesheetEntry.controls.description.hasError('limitExceeds') ||
                editTimesheetEntry.controls.description.hasError('descriptionRequired')
              ) &&
              editTimesheetEntry.controls.description.hasError('invalidDescription')
            "
            >{{ validationMessages.allowedCharactersForDescription }}
          </mat-error>
        </div>
      </div>
      <div class="col-sm-12">
        <div class="form-field form-field-select form-filed-icon">
          <label for="editTimesheetTime">
            <span *ngIf="!endTimerParams.isEndTimerEdit; else timeFieldNameChanged">Time</span>
            <ng-template #timeFieldNameChanged>
              <span
                *ngIf="
                  (editTimeSheetValuesChanged.isCategoryChanged ||
                    editTimeSheetValuesChanged.isClientChanged ||
                    editTimeSheetValuesChanged.isDescriptionChanged ||
                    editTimeSheetValuesChanged.isMatterChanged ||
                    editTimeSheetValuesChanged.isTimeEntryTypeChanged) &&
                    !!timesheetDetail?.lastLoggedTime;
                  else noFieldChanged
                "
                >{{ validationMessages.timeNewBookingDetailsChanged }}</span
              >
              <ng-template #noFieldChanged>
                <span *ngIf="lastLoggedTimeNumber; else newTimeBooking">{{
                  validationMessages.timeContinuingBookingSameDetails
                }}</span>
                <ng-template #newTimeBooking>
                  <span>{{ validationMessages.timeNewBooking }}</span>
                </ng-template>
              </ng-template>
            </ng-template>
          </label>
          <input
            id="editTimesheetTime"
            matInput
            type="text"
            formControlName="time"
            [placeholder]="validationMessages.timeSheetTimePlaceholder"
            (input)="checkIfRoundingApplies(this.selectedTimeEntryTypeEditTimesheetEntry, this.selectedClientEditTimesheetEntry ?? 0, this.selectedMatterEditTimesheetEntry)"
            name="time"
          />
          <svg-icon
            [name]="
              isTimerRunning &&
              currentTimer?.timesheetId === timesheetDetail.id &&
              editTimesheetEntry.controls.time.disabled
                ? 'clock-started-icon'
                : 'clock-icon'
            "
            class="input-icon edit-timesheet-time-icon"
            [ngClass]="{
              'timer-stop-enabled':
                isTimerRunning &&
                currentTimer?.timesheetId === timesheetDetail.id &&
                editTimesheetEntry.controls.time.disabled
            }"
            (click)="stopRunningTimerFromTimeSheetEdit()"
          ></svg-icon>
          <div class="rounding-label" *ngIf="showRoundingApplied()">Rounding will be applied to billed time</div>
          <mat-error
            *ngIf="
              editTimesheetEntry?.controls?.time?.touched &&
              editTimesheetEntry?.controls?.time?.hasError('isTimeInvalid')
            "
            >{{ validationMessages.timeSheetTimeInvalidFormat }}</mat-error
          >
          <mat-error
            *ngIf="
              editTimesheetEntry?.controls?.time?.touched &&
              editTimesheetEntry?.controls?.time?.hasError('isTimeExceeded')
            "
            >{{ validationMessages.timeSheetTimeInvalid }}</mat-error
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <button
          type="button"
          class="btn btn-lg"
          [disabled]="
            !editTimesheetEntry.valid ||
            (selectedMatterEditTimesheetEntry && !isSelectedMatterEditTimesheetEntryActive) ||
            (!selectedCategoryOfEditTimesheetEntry &&
              selectedTimeEntryTypeEditTimesheetEntry !== timeSheetTimeEntryTypeIDs.TempTime) ||
            (!selectedMatterEditTimesheetEntry &&
              selectedTimeEntryTypeEditTimesheetEntry !== timeSheetTimeEntryTypeIDs.TempTime) ||
            (!selectedClientEditTimesheetEntry &&
              selectedTimeEntryTypeEditTimesheetEntry !== timeSheetTimeEntryTypeIDs.TempTime)
          "
          (click)="stopRunningTimerSaveTimesheetDetails()"
        >
          Save
        </button>
      </div>
      <div class="col-sm-6">
        <button type="button" class="btn btn-lg btn-outline" (click)="cancelEditTimeSheet()">Cancel</button>
      </div>
    </div>
  </form>
</div>
