import { Injectable } from '@angular/core';
import { MenuItemLabels } from './menu-item-labels.enum';

const MENUITEMS = [
  {
    label: MenuItemLabels.financeDashboard,
    route: '/admin/finance-dashboard',
    iconClasses: 'menu-icon finance',
    roles: [],
  },
  {
    label: MenuItemLabels.GroupHeadDashboard,
    route: '/admin/group-head-dashboard',
    iconClasses: 'menu-icon group-head-icon',
    roles: [],
  },
  {
    label: MenuItemLabels.MyDashboard,
    route: '/admin/my-dashboard',
    iconClasses: 'menu-icon home-icon',
    roles: [],
  },
  {
    label: MenuItemLabels.Clients,
    route: '/admin/clients',
    iconClasses: 'menu-icon client-icon',
    roles: [],
  },
  {
    label: MenuItemLabels.Matters,
    roles: [],
    route: '/admin/matter',
    iconClasses: 'menu-icon matter-icon',
  },
  {
    label: MenuItemLabels.Timesheets,
    roles: [],
    route: '/admin/timesheet',
    iconClasses: 'menu-icon timesheet-icon',
  },
  {
    label: MenuItemLabels.Billing,
    roles: [],
    iconClasses: 'menu-icon invoice-icon',
    children: [
      {
        label: MenuItemLabels.BillingWip,
        roles: [],
        route: '/admin/billing/wip',
        iconClasses: 'menu-icon on-demand-icon',
      },
      {
        label: MenuItemLabels.BillingRuns,
        roles: [],
        route: '/admin/billing/runs',
        iconClasses: 'menu-icon on-demand-timesheet-icon',
      },
      {
        label: MenuItemLabels.accruedHours,
        roles: [],
        route: '/admin/billing/accruedHours',
        iconClasses: 'menu-icon on-demand-timesheet-icon',
      },
    ],
  },
  {
    label: MenuItemLabels.Disbursement,
    route: '/admin/disbursement',
    roles: [],
    iconClasses: 'menu-icon disbursement-icon',
  },
  {
    label: MenuItemLabels.TimesheetReport,
    route: '/admin/timesheetreport',
    roles: [],
    iconClasses: 'menu-icon report-icon',
    linkActiveExact: false,
  },
  {
    label: MenuItemLabels.BusinessReporting,
    route: '/admin/reports',
    roles: [],
    iconClasses: 'menu-icon book',
    linkActiveExact: false,
  },
  {
    label: MenuItemLabels.WorkRequests,
    roles: [],
    route: '/admin/workrequest',
    iconClasses: 'menu-icon brifecase-timer',
  },
  {
    label: MenuItemLabels.Users,
    route: '/admin/users',
    iconClasses: 'menu-icon users-icon',
    roles: [],
  },
  // {
  //   label: MenuItemLabels.Settings,
  //   route: '/admin/settings',
  //   roles: [],
  //   iconClasses: 'menu-icon setting-icon',
  // },
  {
    label: MenuItemLabels.Configuration,
    iconClasses: 'menu-icon configuration-icon',
    roles: [],
    children: [
      {
        label: MenuItemLabels.Roles,
        roles: [],
        route: '/admin/role',
        iconClasses: 'menu-icon role-icon',
      },
      {
        label: MenuItemLabels.SystemConfiguration,
        roles: [],
        route: '/admin/systemconfig',
        iconClasses: 'menu-icon systemconfig-icon',
      },
    ],
  },
  {
    label: MenuItemLabels.Feedback,
    route: '/admin/feedbacks',
    iconClasses: 'menu-icon feedback-list',
    roles: [],
  },
  {
    label: MenuItemLabels.AiChat,
    route: '/admin/chat',
    iconClasses: 'menu-icon ai-chat',
    roles: [],
  },
  {
    label: MenuItemLabels.ClientRates,
    route: '/admin/client-rates',
    iconClasses: 'menu-icon client-rates',
    roles: [],
  },
  {
    label: MenuItemLabels.Logout,
    route: '/logout',
    iconClasses: 'menu-icon logout-icon',
  },
];

@Injectable()
export class MenuItems {
  getMenuitem() {
    return MENUITEMS;
  }
}
