import * as i0 from '@angular/core';
import { Directive, Optional, Input, HostListener, NgModule } from '@angular/core';
import * as i1 from '@angular/forms';
class NgxTrimDirective {
  constructor(elementRef, ngControl) {
    this.elementRef = elementRef;
    this.ngControl = ngControl;
    this.trimOnWriteValue = true;
  }
  set trim(trimOption) {
    if (trimOption !== '' && trimOption !== 'blur' && trimOption !== false) {
      console.warn(`Note: The value ${JSON.stringify(trimOption)} is not assignable to the trim attribute.
        Only blank string (""), "blur" or false is allowed.`);
      this._trim = false;
      return;
    }
    this._trim = trimOption;
    const elem = this.elementRef.nativeElement;
    const eleValue = elem.value;
    if (trimOption !== false && eleValue !== eleValue.trim()) {
      // initially trim the value if needed
      NgxTrimDirective.dispatchEvent(elem, 'blur');
    }
  }
  get trim() {
    return this._trim;
  }
  static getCaret(el) {
    return {
      start: el.selectionStart,
      end: el.selectionEnd
    };
  }
  static setCaret(el, start, end) {
    el.selectionStart = start;
    el.selectionEnd = end;
    el.focus();
  }
  static dispatchEvent(el, eventType) {
    const event = document.createEvent('Event');
    event.initEvent(eventType, false, false);
    el.dispatchEvent(event);
  }
  static trimValue(el, value) {
    el.value = value.trim();
    NgxTrimDirective.dispatchEvent(el, 'input');
  }
  ngOnInit() {
    if (!this.ngControl) {
      console.warn('Note: The trim directive should be used with one of ngModel, formControl or formControlName directives.');
      return;
    }
    this._valueAccessor = this.ngControl.valueAccessor;
    this._writeValue = this._valueAccessor.writeValue;
    this._valueAccessor.writeValue = value => {
      const _value = this.trim === false || !value || 'function' !== typeof value.trim || !this.trimOnWriteValue ? value : value.trim();
      if (this._writeValue) {
        this._writeValue.call(this._valueAccessor, _value);
      }
      if (value !== _value) {
        if (this._valueAccessor['onChange']) {
          this._valueAccessor['onChange'](_value);
        }
        if (this._valueAccessor['onTouched']) {
          this._valueAccessor['onTouched']();
        }
      }
    };
  }
  ngOnDestroy() {
    if (this._valueAccessor && this._writeValue) {
      this._valueAccessor.writeValue = this._writeValue;
    }
  }
  onBlur(el, value) {
    if (this.trim === false) {
      return;
    }
    if ((this.trim === '' || 'blur' === this.trim) && 'function' === typeof value.trim && value.trim() !== value) {
      NgxTrimDirective.trimValue(el, value);
      NgxTrimDirective.dispatchEvent(el, 'blur'); // in case updateOn is set to blur
    }
  }
  onInput(el, value) {
    if (this.trim === false) {
      return;
    }
    if (this.trim === '' && 'function' === typeof value.trim && value.trim() !== value) {
      let {
        start,
        end
      } = NgxTrimDirective.getCaret(el);
      if (value[0] === ' ' && start === 1 && end === 1) {
        start = 0;
        end = 0;
      }
      NgxTrimDirective.trimValue(el, value);
      NgxTrimDirective.setCaret(el, start, end);
    }
  }
}
/** @nocollapse */ /** @nocollapse */
NgxTrimDirective.ɵfac = function NgxTrimDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || NgxTrimDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1.NgControl, 8));
};
/** @nocollapse */ /** @nocollapse */
NgxTrimDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: NgxTrimDirective,
  selectors: [["input", "trim", ""], ["textarea", "trim", ""]],
  hostBindings: function NgxTrimDirective_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("blur", function NgxTrimDirective_blur_HostBindingHandler($event) {
        return ctx.onBlur($event.target, $event.target.value);
      })("input", function NgxTrimDirective_input_HostBindingHandler($event) {
        return ctx.onInput($event.target, $event.target.value);
      });
    }
  },
  inputs: {
    trim: "trim",
    trimOnWriteValue: "trimOnWriteValue"
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxTrimDirective, [{
    type: Directive,
    args: [{
      selector: 'input[trim],textarea[trim]'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i1.NgControl,
      decorators: [{
        type: Optional
      }]
    }];
  }, {
    trim: [{
      type: Input,
      args: ['trim']
    }],
    trimOnWriteValue: [{
      type: Input
    }],
    onBlur: [{
      type: HostListener,
      args: ['blur', ['$event.target', '$event.target.value']]
    }],
    onInput: [{
      type: HostListener,
      args: ['input', ['$event.target', '$event.target.value']]
    }]
  });
})();
class NgxTrimDirectiveModule {}
/** @nocollapse */ /** @nocollapse */
NgxTrimDirectiveModule.ɵfac = function NgxTrimDirectiveModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || NgxTrimDirectiveModule)();
};
/** @nocollapse */ /** @nocollapse */
NgxTrimDirectiveModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: NgxTrimDirectiveModule
});
/** @nocollapse */ /** @nocollapse */
NgxTrimDirectiveModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxTrimDirectiveModule, [{
    type: NgModule,
    args: [{
      imports: [],
      declarations: [NgxTrimDirective],
      exports: [NgxTrimDirective]
    }]
  }], null, null);
})();

/*
 * Public API Surface of ngx-trim-directive
 */

/**
 * Generated bundle index. Do not edit.
 */

export { NgxTrimDirective, NgxTrimDirectiveModule };
