<div class="modal-body">
  <div class="manual-header">
    <div class="manual-req-header">
      <h6>Manual Request</h6>
      <button type="button" class="close" (click)="closeModal.emit()" aria-label="Close">
        <svg-icon name="modal-close-icon" class="icon"></svg-icon>
      </button>
    </div>
    <div
      *ngIf="!isManualTimeEntryDateValid && selectedTimeEntryType !== timeSheetTimeEntryTypeIDs.TempTime"
      class="manual-req-warning"
    >
      <p>You cannot add time entry to this matter.</p>
      <p>The time entries have been locked until {{ lockedDateToBeDisplayed }}.</p>
      <p>Please contact the administrator for futher information.</p>
    </div>
  </div>
  <mat-tab-group mat-align-tabs="start" (selectedTabChange)="tabClick($event)">
    <mat-tab label="Add New Time Entry">
      <form [formGroup]="manualRequestForm">
        <div class="row">
          <div class="col-xs-12 col-md-6">
            <div class="form-field form-field-select">
              <label for="manualTimesheetTimeEntryType">Time Entry Type</label>
              <ng-select
                labelForId="manualTimesheetTimeEntryType"
                [items]="timeEntryTypes"
                [multiple]="false"
                [closeOnSelect]="true"
                [searchable]="true"
                bindLabel="timeEntryTypeName"
                bindValue="id"
                [clearable]="false"
                placeholder="Time Entry"
                [ngModel]="selectedTimeEntryType"
                [ngModelOptions]="{ standalone: true }"
                (change)="timeEntryTypeSelected($event)"
              >
              </ng-select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-md-6">
            <div class="form-field form-field-select date-input manual-date-input" (click)="openDatePicker()">
              <label for="manualTimesheetDate">Date</label>
              <mat-form-field appearance="fill">
                <input
                  id="manualTimesheetDate"
                  id="dateManualEntry"
                  #date
                  matInput
                  (dateChange)="dateFilterSelected()"
                  formControlName="date"
                  (keydown)="disableDate()"
                  [matDatepicker]="picker"
                  [max]="todayDate"
                  placeholder="Select Date"
                  readonly
                />
                <mat-icon matDatepickerToggleIcon (click)="clearDate($event)">clear</mat-icon>
                <mat-datepicker-toggle matSuffix [for]="picker"> </mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
          <div class="col-xs-12 col-md-6">
            <div class="form-field form-field-select">
              <label for="manualTimesheetLegalTeamMember">Legal Team Member</label>
              <ng-select
                labelForId="manualTimesheetLegalTeamMember"
                [items]="lawyerListOfManualEntry"
                [multiple]="false"
                [closeOnSelect]="true"
                [searchable]="true"
                bindLabel="lawyerName"
                bindValue="id"
                [(ngModel)]="selectedLawyerManualEntry"
                [ngModelOptions]="{ standalone: true }"
                placeholder="Select Legal Team Member"
                [disabled]="!isAdmin"
                (change)="lawyerOfManualEntrySelected()"
              >
              </ng-select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-md-6">
            <div class="form-field form-field-select">
              <label for="manualTimesheetClient">Client</label>
              <ng-select
                labelForId="manualTimesheetClient"
                [items]="clientListOfManualEntry"
                [multiple]="false"
                [closeOnSelect]="true"
                [searchable]="true"
                bindLabel="clientName"
                bindValue="id"
                [(ngModel)]="selectedClientManualEntry"
                [ngModelOptions]="{ standalone: true }"
                placeholder="Select Client"
                [virtualScroll]="true"
                (change)="onClientSelectionChanged($event); getMattersByClientId()"
                [disabled]="selectedTimeEntryType === 2"
              >
              </ng-select>
            </div>
          </div>
          <div class="col-xs-12 col-md-6">
            <div class="form-field form-field-select">
              <label for="manualTimesheetMatter">Matter</label>
              <ng-select
                class="form-field-select"
                appendTo="body"
                labelForId="manualTimesheetMatter"
                [items]="matterListOfManualEntry"
                [multiple]="false"
                [closeOnSelect]="true"
                [searchable]="true"
                bindLabel="matterName"
                bindValue="id"
                [(ngModel)]="selectedMatterManualEntry"
                [ngModelOptions]="{ standalone: true }"
                placeholder="Select Matter"
                [virtualScroll]="true"
                (change)="getClientByMatterID($event);checkIfRoundingApplies(selectedTimeEntryType, selectedClientManualEntry ?? 0, $event.id)"
                [disabled]="selectedTimeEntryType === 3"
              >
              </ng-select>
              <mat-error *ngIf="selectedMatterManualEntry && !isSelectedMatterManualEntryActive"
                >Matter is inactive
              </mat-error>
            </div>
          </div>
          <div class="no-matter-assigned-error" *ngIf="!isPreviouslySelectedClientExistManualTimesheet">
            <mat-error
              >Matters for <u>{{ previouslySelectedClientNameManualTimesheet }}</u> is not assigned to you.
            </mat-error>
          </div>
          <div class="col-xs-12 col-md-6">
            <div class="form-field form-field-select">
              <label for="manualTimesheetHeadlinePracticeArea">Headline Practice Area</label>
              <ng-select
                labelForId="manualTimesheetHeadlinePracticeArea"
                [items]="headLinePracticeAreaByMatterId"
                [multiple]="false"
                [closeOnSelect]="true"
                [searchable]="true"
                bindLabel="headlinePracticeAreaName"
                bindValue="id"
                [(ngModel)]="selectedHeadlinePracticeAreaManualEntry"
                [ngModelOptions]="{ standalone: true }"
                placeholder="Headline Practice Area"
                [virtualScroll]="true"
                [disabled]="true"
              >
              </ng-select>
            </div>
          </div>
          <div class="col-xs-12 col-md-6">
            <div class="form-field form-field-select">
              <label for="manualTimesheetPhase">Phase</label>
              <ng-select
                labelForId="manualTimesheetPhase"
                [items]="phaseByMatterId"
                [multiple]="false"
                [closeOnSelect]="true"
                [searchable]="true"
                [(ngModel)]="selectedPhaseManualEntry"
                [disabled]="selectedTimeEntryType === 3"
                [ngModelOptions]="{ standalone: true }"
                placeholder="Select Phase"
                [virtualScroll]="true"
              >
              </ng-select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-md-6">
            <div class="form-field form-field-select">
              <label for="manualTimesheetCategory">Category</label>
              <ng-select
                labelForId="manualTimesheetCategory"
                [items]="categoryListOfManualEntry"
                [multiple]="false"
                [closeOnSelect]="true"
                [searchable]="true"
                bindLabel="categoryName"
                bindValue="id"
                [(ngModel)]="selectedCategoryOfManualEntry"
                [ngModelOptions]="{ standalone: true }"
                placeholder="Select Category"
              >
              </ng-select>
            </div>
          </div>
          <div class="col-xs-12 col-md-6">
            <div class="form-field form-field-select">
              <label for="manualTimesheetTime">Time</label>
              <input
                id="manualTimesheetTime"
                matInput
                type="text"
                formControlName="time"
                [placeholder]="validationMessages.timeSheetTimePlaceholder"
                (input)="checkIfRoundingApplies(selectedTimeEntryType, selectedClientManualEntry ?? 0, selectedMatterManualEntry)"
                name="time"
              />
              <svg-icon name="clock-icon" class="input-icon"></svg-icon>
              <div class="rounding-label" *ngIf="showRoundingApplied()">Rounding will be applied to billed time</div>
              <mat-error
                *ngIf="
                  manualRequestForm?.controls?.time?.touched &&
                  manualRequestForm?.controls?.time?.hasError('isTimeInvalid')
                "
                >{{ validationMessages.timeSheetTimeInvalidFormat }}</mat-error
              >
              <mat-error
                *ngIf="
                  manualRequestForm?.controls?.time?.touched &&
                  manualRequestForm?.controls?.time?.hasError('isTimeExceeded')
                "
                >{{ validationMessages.timeSheetTimeInvalid }}</mat-error
              >
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-md-12">
            <div class="form-field form-field-select desc-input">
              <label for="manualTimesheetDescription">Description</label>
              <textarea
                id="manualTimesheetDescription"
                matInput
                formControlName="description"
                placeholder="Enter Description"
                name="description"
                rows="1"
              ></textarea>
              <svg-icon name="matter-icon" class="input-icon"></svg-icon>
              <mat-error
                *ngIf="
                  manualRequestForm.controls.description.touched &&
                  manualRequestForm.controls.description.hasError('descriptionRequired') &&
                  !manualRequestForm.controls.description.hasError('invalidDescription')
                "
                >{{ validationMessages.descriptionRequired }}
              </mat-error>
              <mat-error
                *ngIf="
                  manualRequestForm.controls.description.touched &&
                  manualRequestForm.controls.description.hasError('limitExceeds') &&
                  !manualRequestForm.controls.description.hasError('invalidDescription')
                "
                >{{ validationMessages.maxLength2000ForDescription }}
              </mat-error>
              <mat-error
                *ngIf="
                  manualRequestForm.controls.description.touched &&
                  !(
                    manualRequestForm.controls.description.hasError('limitExceeds') ||
                    manualRequestForm.controls.description.hasError('descriptionRequired')
                  ) &&
                  manualRequestForm.controls.description.hasError('invalidDescription')
                "
                >{{ validationMessages.allowedCharactersForDescription }}
              </mat-error>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-md-6">
            <button
              type="button"
              [disabled]="
                !manualRequestForm.valid ||
                (selectedMatterManualEntry && !isSelectedMatterManualEntryActive) ||
                (!isManualTimeEntryDateValid && selectedTimeEntryType !== timeSheetTimeEntryTypeIDs.TempTime) ||
                (!selectedCategoryOfManualEntry && selectedTimeEntryType !== timeSheetTimeEntryTypeIDs.TempTime) ||
                !selectedLawyerManualEntry ||
                (!selectedMatterManualEntry && selectedTimeEntryType !== timeSheetTimeEntryTypeIDs.TempTime) ||
                (!selectedClientManualEntry && selectedTimeEntryType !== timeSheetTimeEntryTypeIDs.TempTime) ||
                isEntryAdded
              "
              class="btn time-entry-btn"
              (click)="addManualRequest()"
            >
              Add Time Entry
            </button>
          </div>
          <div class="col-xs-12 col-md-6">
            <button type="button" class="btn time-entry-btn btn-outline" (click)="closeModal.emit()">Cancel</button>
          </div>
        </div>
      </form>
    </mat-tab>
    <mat-tab label="Log">
      <div class="data-table">
        <div class="table-responsive listing-table">
          <table
            mat-table
            [dataSource]="logListDataSource"
            matSort
            matSortActive="clientName"
            matSortDirection="asc"
            matSortDisableClear
            class="table"
            aria-describedby="myDesc"
          >
            <ng-container matColumnDef="clientName">
              <th mat-header-cell *matHeaderCellDef>Client</th>
              <td mat-cell *matCellDef="let element">{{ element.clientName }}</td>
            </ng-container>

            <ng-container matColumnDef="matterName">
              <th mat-header-cell *matHeaderCellDef>Matter</th>
              <td mat-cell *matCellDef="let element">{{ element.matterName || '--' }}</td>
            </ng-container>

            <ng-container matColumnDef="userName">
              <th mat-header-cell *matHeaderCellDef>Legal Team Member</th>
              <td mat-cell *matCellDef="let element">{{ element.userName }}</td>
            </ng-container>

            <ng-container matColumnDef="headlinePracticeArea">
              <th mat-header-cell *matHeaderCellDef>Headline Practice Area</th>
              <td mat-cell *matCellDef="let element">{{ element.headlinePracticeArea || '--' }}</td>
            </ng-container>

            <ng-container matColumnDef="phase">
              <th mat-header-cell *matHeaderCellDef>Phase</th>
              <td mat-cell *matCellDef="let element">{{ element.phase || '--' }}</td>
            </ng-container>

            <ng-container matColumnDef="categoryName">
              <th mat-header-cell *matHeaderCellDef>Category</th>
              <td mat-cell *matCellDef="let element">{{ element.categoryName }}</td>
            </ng-container>

            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef>Date</th>
              <td mat-cell *matCellDef="let element">{{ element.timesheetDate }}</td>
            </ng-container>

            <ng-container matColumnDef="duration">
              <th mat-header-cell *matHeaderCellDef>Duration</th>
              <td mat-cell *matCellDef="let element">{{ element.duration || 0 }} hours</td>
            </ng-container>

            <ng-container matColumnDef="description">
              <th mat-header-cell *matHeaderCellDef>Description</th>
              <td mat-cell *matCellDef="let element">{{ element.description || '--' }}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
          <div *ngIf="ifNoDataFound">
            <p>Data not found</p>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
